<template>
  <section
      class="w-full relative overflow-hidden"
      style="background: radial-gradient(162.55% 98.99% at 73.6% 64.69%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%)"
  >
    <div class="absolute inset-0 lg:block hidden">
      <div class="absolute top-0 -left-[10%] z-1">
        <img src="/images/circle/hinh4.svg" alt="circle" class="w-full h-full object-contain"/>
      </div>
      <div class="absolute bottom-0 right-[10%] z-1">
        <img src="/images/circle/hinh5.svg" alt="circle" class="w-full h-full object-contain"/>
      </div>
      <div class="absolute top-0 right-0 z-1">
        <img src="/images/circle/hinh6.svg" alt="circle" class="w-full h-full object-contain"/>
      </div>
    </div>
    <div class="bottom-0 right-0 absolute z-1 lg:hidden block">
      <img class="w-full h-full object-contain" src="/images/circle/hinh3.svg" alt="circle"/>
    </div>

    <div
        class="p-9.5 pb-0 lg:pt-25 container relative z-5 flex gap-10 lg:gap-15 lg:gap-0 lg:flex-row flex-col lg:justify-between"
    >
      <div class="">
        <v-text
            data-aos="fade-right"
            data-aos-once="true"
            class="text-3xl leading-10 md:text-44px md:leading-50px font-[550] text-white lg:max-w-100 mb-7.5 w-full"
            option_key="mobileAppPage.titleDownload"
            default_value="Tải ứng dụng IZIon24 ngay"
        ></v-text>
        <button-primary
            data-aos="fade-up"
            data-aos-once="true"
            :title="$t('DOWNLOAD')"
            :url="links_global?.download_app"
            class="!xl:flex !hidden"
            option
            :click-tracking-handler="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_BY_LINK_KEY)"
        ></button-primary>

        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="flex md:flex-row flex-col gap-6 md:divide-x-1 md:divide-white/40 lg:mt-36 mt-5"
        >
          <div class="flex flex-col gap-2.5">
            <v-text
                class="text-white/60 font-semibold text-sm leading-6 uppercase"
                option_key="subTitleBanerDownload1.global"
            ></v-text>
            <div class="flex md:flex-col gap-2.5">
              <a
                  :href="links_global?.appstore"
                  @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
                  target="_blank"
                  class="block w-37.5 aspect-38/12 overflow-hidden"
              >
                <img
                    src="/images/global/appstore.svg"
                    alt="appstore"
                    class="w-full h-full object-contain"
                />
              </a>
              <a
                  :href="links_global?.google_play"
                  @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
                  target="_blank"
                  class="block w-37.5 aspect-38/12 overflow-hidden"
              >
                <img
                    src="/images/global/googlePlay.svg"
                    alt="googleplay"
                    class="w-full h-full object-contain"
                />
              </a>
            </div>
          </div>
          <div class="md:pl-6 flex flex-col gap-2.5">
            <v-text
                class="text-white/60 font-semibold text-sm leading-6 uppercase"
                option_key="subTitleBanerDownload2.global"
            ></v-text>

            <div class="!w-27 aspect-1/1">
              <img
                  :src="getThumbnail(links_global?.qr, 162, true)"
                  alt="qr"
                  class="w-full h-full object-contain rounded-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true" class="flex justify-center">
        <div class="w-150 aspect-117/107 transform translate-y-2">
          <v-image
              option_key="mobileAppPage.imageDownload2"
              :width="800"
              class="w-full h-full object-contain rounded-15px"
          >
          </v-image>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  DOWNLOAD_EVENT_ID,
  DOWNLOAD_APP_ON_APP_STORE_KEY,
  DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY,
  DOWNLOAD_APP_BY_LINK_KEY,
  useEventTracking
} from '../../../composables/ackee/event'

const links_global = inject('globalSettings')?.links[0]
</script>

<style></style>
